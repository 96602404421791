import {useEffect, useState} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
//import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/core/store/store.ts";
import getMyProfileRequest from "../../app/core/api/requests/me/getMyProfileRequest.ts";
import getFrontendUtilitiesRequest from "../../app/core/api/requests/utils/getFrontUtilitiesRequest.ts";
import getMyCompaniesRequest from "../../app/core/api/requests/me/getMyCompaniesRequest.ts";
import loadFilters from "../../app/core/utils/loadFilters.ts";
import {setFilters, setImpersonate} from "../../app/core/store/slices/futilsSlice.ts";
import {endLoading, startLoading} from "../../app/core/store/slices/loadingSlice.ts";
import getPictureOrAvatar from "../../app/core/utils/getPictureOrAvatar.ts";
import {setProfilePicture} from "../../app/core/store/slices/userMeSlice.ts";
import {getImpersonateSession, removeImpersonateSession, saveSession} from "../../app/core/utils/sessionStorage.ts";
import {setSession} from "../../app/core/store/slices/sessionSlice.ts";
import logoutRequest from "../../app/core/api/requests/auth/logoutRequest.ts";

const MasterLayout = () => {
  const location = useLocation()
  const userMe = useSelector((state: RootState) => state.userMe)
  const futils = useSelector((state: RootState) => state.frontendUtilities)

  const dispatch = useDispatch()

  const getMyProfile = async () => await getMyProfileRequest(dispatch);

  const getFrontUtilities = async () => await getFrontendUtilitiesRequest(dispatch);

  const getCompanies = async () => {
    await getMyCompaniesRequest(dispatch)
  }

  const getFilters = async () => {
    const filters = await loadFilters(dispatch);
    dispatch(setFilters(filters))
  }

  const initialRequests = async () => {
    dispatch(startLoading())
    dispatch(setImpersonate())
    await Promise.all([
      getCompanies(),
      getFilters(),
      getMyProfile(),
      getFrontUtilities(),
    ])
    dispatch(endLoading())
  }

  useEffect(() => {
    const u = getPictureOrAvatar(
      `${userMe?.user?.name} ${userMe?.user?.lastName}`,
      userMe?.user?.profileImage || '',
      true,
    )

    if (!u) return
    dispatch(setProfilePicture(u))
  }, [userMe.user]);

  useEffect(() => {
    initialRequests()
  }, [])

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const switchToUnimpersonate = async () => {
      const imp = getImpersonateSession()
      if (!imp) return

      await logoutRequest(dispatch, {
        closeAllSessions: false,
      })
      dispatch(setSession(imp))
      saveSession(imp)
      removeImpersonateSession()
      window.location.reload()
  }

  return (
    <PageDataProvider>
      {futils?.impersonate &&
        <div
          className={'position-fixed alert alert-warning top-0 start-25 w-50 d-flex align-items-center justify-content-between'}
          style={{height: 60, zIndex: 1000, marginTop: 5}}>
          <h5 className={'p-0 m-0'}>Impersonate Mode - Developers</h5>

          <button
            className={'btn btn-warning btn-sm'}
            onClick={switchToUnimpersonate}
          >
            Go back to my session
          </button>
        </div>
      }


      <div className='d-flex flex-column flex-root app-root vh-100' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper/>
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar/>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <Outlet/>
              </div>
              <FooterWrapper/>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}
      <ScrollTop/>
    </PageDataProvider>
  )
}

export {MasterLayout}
