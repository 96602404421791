import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import ISession from "../../interfaces/ISession";

const initialState: ISession = {
  token: ''
};

const sessionSlice = createSlice({
  name: EStores.SESSION,
  initialState,
  reducers: {
    setSession(state: ISession, data: PayloadAction<ISession>) {
      return {
        ...state,
        ...data.payload,
      }
    },
    logout(state: ISession) {
      sessionStorage.clear()
      localStorage.clear()
      state = initialState
      return state
    }
  },
});

export const {
  setSession,
  logout,
} = sessionSlice.actions;

export default sessionSlice.reducer;
