import * as routes from "./routes.json"
import axios from "axios";
import {getSession} from "../utils/sessionStorage";
import ERoutes from "../enums/ERoutes";

export interface Route {
  method: string;
  path: string;
  session: boolean;
}

export interface IResponse<Data = any> {
  statusCode: number;
  data: Data;
}

export class RACARequest<T = any> {
  private route: Route;
  private url: string;
  private data: T | undefined;
  private queryParams: string[] = []
  private token?: string;
  private formData?: boolean;

  constructor(module: string, fn: string) {
    this.route  = this.getRoute(module, fn)
    this.url    = this.getBaseUrl() + this.route.path

    if (this.route.session) {
      this.useSession()
    }
  }

  public setPathValues(row: string, value: string): RACARequest {
    if(!row || !value) {
      window.location.replace(ERoutes.AUTH)
      localStorage.clear()
      return this
    }
    this.url = this.url.replace(`{${row}}`, value)
    return this
  }

  public useFormData(): RACARequest {
    this.formData = true;
    return this
  }

  public setQueryParameters<T = any>(parameters: T): RACARequest {
    for (const parametersKey in parameters) {
      this.setQueryParams(parametersKey, parameters[parametersKey])
    }

    return this;
  }

  public setQueryParams(key: string, value: any): RACARequest {
    if(!key || !value) return this
    let queryValue = `${key}=${value}`
    this.queryParams.push(queryValue)
    return this;
  }

  private getBaseUrl(): string {
    const url = import.meta.env.VITE_APP_API_URL
    if(!url) throw new Error("error api url")
    return url
  }

  private useSession(): RACARequest {
    const session = getSession()
    if (!session?.token) {
      window.location.replace(ERoutes.AUTH)
      localStorage.clear()
      return this
    }

    this.token = session.token;
    return this;
  }

  public setToken(token: string): RACARequest {
    this.token = token
    return this
  }

  private getAuthorization(): string {
    const token = this.token
    return `Bearer ${token}`
  }

  private getRoute(module: string, fn: string): Route {
    if(!module || !fn) throw new Error("undefined route")

    // @ts-ignore
    const r = routes[module][fn]
    if(!r) throw new Error("undefined route")

    return {
      path: r.path,
      method: r.method,
      session: r.session
    }
  }

  public setData(d: T): this {
    this.data = d
    return this
  }

  private prepareQueryParameters(): void {
    if(this.queryParams.length == 0) return

    for(let i = 0; i < this.queryParams.length; i++) {
      let param = this.queryParams[i]
      this.url += i == 0 ? "?" : "&";
      this.url += param
    }

    return
  }

  private getContentType(): string {
    if (this.formData) {
      return 'multipart/form-data'
    } else return 'application/json'
  }

  public async makeBlobRequest(): Promise<IResponse> {
    try {
      this.prepareQueryParameters()
      // console.log(`\u001b[34mmaking request [${this.route.method.toUpperCase()}] - ${this.url}\u001b[0m`)

      const req = await axios.request({
        method: this.route.method,
        url: this.url,
        headers: {
          Authorization: this.getAuthorization(),
        },
        responseType: 'blob',
        data: this.data
      })

      let response: IResponse = {
        statusCode: req.status,
        data: req.data
      }

      // console.log(`\u001b[32mrequest response [${this.route.method.toUpperCase()}] - ${this.url} | [${response.statusCode}]\u001b[0m`)
      return response
    } catch (e: any) {
      // console.log(`\u001b[31mrequest response error [${this.route.method.toUpperCase()}] - ${this.url} | [${e?.response?.status}]\u001b[0m`)
      return {
        statusCode: e?.response?.status || 0,
        data: e?.response?.data || "no data content"
      }
    }
  }

  public async makeRequest(): Promise<IResponse> {
    try {
      this.prepareQueryParameters()
      // console.log(`\u001b[34mmaking request [${this.route.method.toUpperCase()}] - ${this.url}\u001b[0m`)

      const req = await axios.request({
        method: this.route.method,
        url: this.url,
        headers: {
          Authorization: this.getAuthorization(),
          'Content-Type': this.getContentType(),
        },
        data: this.data
      })

      let response: IResponse = {
        statusCode: req.status,
        data: req.data
      }

      // console.log(`\u001b[32mrequest response [${this.route.method.toUpperCase()}] - ${this.url} | [${response.statusCode}]\u001b[0m`)
      return response
    } catch (e: any) {
      // console.log(`\u001b[31mrequest response error [${this.route.method.toUpperCase()}] - ${this.url} | [${e?.response?.status}]\u001b[0m`)
      return {
        statusCode: e?.response?.status || 0,
        data: e?.response?.data || "no data content"
      }
    }
  }
}

