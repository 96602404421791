import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ERoutes from "../core/enums/ERoutes.ts";

const PrivateRoutes = () => {

  const StatementsRouter = lazy(() => import('../modules/statements/Router.tsx'))
  const UsersRouter = lazy(() => import('../modules/users/Router.tsx'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path={ERoutes.AUTH_ALL} element={<Navigate to={ERoutes.STATEMENTS} />} />

        {/* Pages */}

        <Route
          path={ERoutes.STATEMENTS_ALL}
          element={
            <SuspensedView>
              <StatementsRouter />
            </SuspensedView>
          } />

        <Route
          path={ERoutes.USERS_ALL}
          element={
            <SuspensedView>
              <UsersRouter />
            </SuspensedView>
          } />

        <Route path='*' element={<Navigate to={ERoutes.STATEMENTS} />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
