import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores.ts';
import IStatement from "../../interfaces/IStatement.ts";
import IParentMovement from "../../interfaces/IParentMovement.ts";
import IStatementFile from "../../interfaces/IStatementFile.ts";

type IStore = {
  statement?: IStatement
  parentMovements?: number;
  miscFiles?: IStatementFile[];
}

const initialState: IStore = {
};

const store = createSlice({
  name: EStores.STATEMENT,
  initialState,
  reducers: {
    setFilesStatement(state: IStore, statement: PayloadAction<IStatementFile[]>) {
      state.miscFiles = statement.payload
      return state
    },
    setParentTotal(state: IStore, parentMovements: PayloadAction<number>) {
      state.parentMovements = parentMovements.payload
      return state
    },
    setStatement(state: IStore, statement: PayloadAction<IStatement>) {
      state.statement = statement.payload
      return state
    },
    clearStatement(state: IStore) {
      state.statement = undefined
      return
    },
  },
});

export const {
  setStatement,
  clearStatement,
  setFilesStatement,
  setParentTotal,
} = store.actions;

export default store.reducer;
