import ITour from "../core/interfaces/ITour.ts";
import ETourSteps from "../core/enums/ETourSteps.ts";

export default {
  [ETourSteps.LIST_STATEMENTS]: {
    name: 'List Statements',
    steps: [
      {
        selector: '#new-statement-button',
        content: 'Hola Crack 🤓! Para comenzar tenes que presionar acá, para crear tu declaración.',
      },
    ]
  },
  [ETourSteps.NEW_STATEMENT_STEP_3]: {
    name: 'New Statement Step 3',
    steps: [
      {
        selector: '#movement-parent-selector-0',
        content: 'Aquí debes seleccionar el tipo de movimiento que deseas realizar.',
      },
    ]
  },
} as { // @ts-ignore
  [key: ETourSteps]: ITour }