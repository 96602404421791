import {useEffect, useState} from 'react'
import {ILayout, useLayout} from '../../core'
import getVersion from "../../../../app/utils/version.ts";

const Footer = () => {
  const {config} = useLayout()
  const [ version, setVersion ] = useState<string>('')

  useEffect(() => {
    updateDOM(config)

    getVersion().then((v) => setVersion(v))
  }, [config])

  return (
    <>
      <div className='text-gray-900 order-2 order-md-1 d-flex fs-8'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <p className={'text-gray-800 me-2'}>Developed by <a href={'https://racasf.com?s=NERD'} target={'_blank'}>RACA SF</a> -</p>
        <p className='text-gray-800 text-hover-primary d-inline-block m-0'>LucaGreen - {version}</p>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
