
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from "react-intl";
import getFrontendUtilitiesRequest from '../../core/api/requests/utils/getFrontUtilitiesRequest';
import { useDispatch } from 'react-redux';

const AuthLayout = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const getFrontUtilities = async () => await getFrontendUtilitiesRequest(dispatch);

  useEffect(() => {
    getFrontUtilities()
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid min-vh-100'>
      <Link to='/' className='d-block d-lg-none mx-auto pt-20'>
        <img alt='Logo' src={toAbsoluteUrl('media/logos/nerdteam-logo.svg')} className='theme-light-show h-100px' />
        <img alt='Logo' src={toAbsoluteUrl('media/logos/nerdteam-logo-dark.svg')} className='theme-dark-show h-100px' />
      </Link>

      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-100 w-md-500px p-10'>
            <Outlet />
          </div>
        </div>
      </div>

      <div
        className='d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat'
        style={{backgroundImage: `url(${toAbsoluteUrl('media/auth/auth-background.png')})`}}
      ></div>
    </div>
  )
}

export {AuthLayout}
