import { Dispatch } from "redux";
import { IResponse } from "../../Request";
import { RACARequest } from "../../Request";
import { startLoading, endLoading } from "../../../store/slices/loadingSlice";
import {getSession} from "../../../utils/sessionStorage.ts";
import {logout} from "../../../store/slices/sessionSlice.ts";
import requestMiddlewares from "../../requestMiddlewares.ts";


export interface ILogoutRequest {
    closeAllSessions: boolean
}

export default async function logoutRequest(
    dispatch: Dispatch,
    data: ILogoutRequest
): Promise<IResponse> {
    const session = getSession()
    if (!session?.token) {
        dispatch(logout())
        window.location.reload()
        throw new Error('Unauthorized')
    }

    dispatch(startLoading());

    const request = new RACARequest('auth', 'logout')
        .setData(data);

    const response = await request.makeRequest();
    requestMiddlewares(
      dispatch,
      response,
    );

    dispatch(endLoading());

    return response;
}
