import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes.ts";
import googleSignUpRequest from "../../../core/api/requests/auth/googleSignUpRequest.ts";
import { useDispatch } from "react-redux";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes.ts";
import sweetalert2 from "sweetalert2";
import { saveSession } from "../../../core/utils/sessionStorage.ts";
import GetReturnURL from "../../../utils/getReturnURL.ts";

export default function(){
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const signUp = async () => {
        
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");

        if (!code) navigate(ERoutes.AUTH)
        
        const requestParams = {
            code,
            returnURL: `${GetReturnURL()}/signup`
        }

        const response = await googleSignUpRequest(dispatch, requestParams)
        if (response.statusCode === EHttpStatusCodes.OK) {
            saveSession(response.data)
            return
          } else {
            sweetalert2.fire(
              `Error`,
              response.data.message,
              'error',
            ).then((result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate(ERoutes.AUTH_SIGNUP)
              }
            });
          }
    }

    useEffect( () => {
        signUp()
        
    }, [])

    return null
}