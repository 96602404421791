/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {App} from '../App'
import {useSelector} from "react-redux";
import {RootState} from "../core/store/store.ts";
import ERoutes from "../core/enums/ERoutes.ts";
import {getSession} from "../core/utils/sessionStorage.ts";
import ISession from "../core/interfaces/ISession.ts";
import {TourProvider} from "@reactour/tour";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {BASE_URL} = import.meta.env

const AppRoutes: FC = () => {
  const session = useSelector((state: RootState) => state.session)
  const [ s, setSession ] = useState<ISession | null>(null)

  const tour = useSelector((state: RootState) => state.tour)

  useEffect(() => {
    const ss = getSession()

    if (!ss && session.token !== '') {
      setSession(session)
      return
    }

    setSession(ss)
  }, [session]);

  return (
    <TourProvider steps={tour.steps}>
      <BrowserRouter basename={BASE_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path={ERoutes.ERROR_ALL} element={<ErrorsPage />} />
            <Route path={ERoutes.LOGOUT} element={<Logout />} />
            {s &&
              <>
                <Route index element={<Navigate to={ERoutes.STATEMENTS} />} />
                <Route path={ERoutes.ALL} element={<PrivateRoutes />} />
              </>
            }

            {!s &&
              <>
                <Route path={ERoutes.AUTH_ALL} element={<AuthPage />} />
                <Route path='*' element={<Navigate to={ERoutes.AUTH} />} />
              </>
            }
          </Route>
        </Routes>
      </BrowserRouter>
    </TourProvider>
  )
}

export {AppRoutes}
