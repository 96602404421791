enum EStores {
    LOADING = 'loading',
    SESSION = 'session',
    PERMISSIONS = 'permissions',
    USER_ME = 'userMe',
    FRONTEND_UTILITIES = 'frontendUtilities',
    SEARCHER = 'searcher',
    COMPANIES = 'companies',
    NOTIFICATIONS = 'notifications',
    MODALS = 'modals',
    STATEMENT = 'statement',
    TOUR = 'tour'
}

export default EStores;
