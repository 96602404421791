import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import ITour from "../../interfaces/ITour.ts";
import tourSteps from "../../../utils/tourSteps.ts";
import ETourSteps from "../../enums/ETourSteps.ts";

type SliceType = {
  tours: { [key: string]: ITour };
  selectedTour: string;
  steps: any[];
}

const initialState: SliceType = {
  tours: tourSteps,
  selectedTour: '',
  steps: []
};

const slice = createSlice({
  name: EStores.TOUR,
  initialState,
  reducers: {
    setTours: (state, action: PayloadAction<{ [key: string]: ITour }>) => {
      state.tours = action.payload;
    },
    setSelectedTour: (state, action: PayloadAction<ETourSteps>) => {
      state.selectedTour = action.payload;
      state.steps = state.tours[action.payload].steps;
    },
  },
});

export const {
  setTours,
  setSelectedTour,
} = slice.actions;

export default slice.reducer;
