enum ERoutes {
  HOME = '/',

  AUTH = '/auth',
  AUTH_ALL = '/auth/*',
  AUTH_SIGNUP = '/auth/signup',
  AUTH_LOGIN = '/auth',
  LOGOUT = 'logout',
  AUTH_PASSWORD_CHANGE = '/auth/password-recovery',
  AUTH_ACCOUNT_ACTIVATION = '/auth/activate-account',
  AUTH_VALIDATE_ACCOUNT = '/auth/validate-account',

  AUTH_PASSWORD_RECOVERY_REQUEST = '/auth/forgot-password', 

  ERROR_ALL = '/error/*',
  ALL = '/*',

  DASHBOARD = '/dashboard',

  STATEMENTS = '/statements',
  STATEMENTS_ALL = '/statements/*',
  STATEMENTS_VIEW = '/statements/:id/view',
  STATEMENTS_BANK_SELECTION = '/statements/:id/bank-selection',
  STATEMENT_MOVEMENTS = '/statements/:id/view/movements',
  STATEMENT_DOCUMENTS = '/statements/:id/view/documents',

  USERS = '/users',
  USERS_ALL = '/users/*',
  USERS_INFORMATION = '/users/:id/information',

  ROLES = '/roles',
  ROLES_ALL = '/roles/*',
  ROLES_INFORMATION = '/roles/:id/information',

  SEARCHER = '/searcher',

  NEW_PARAMETER = 'new'

}

export default ERoutes;
