import ISession from "../interfaces/ISession";

const KEY = `${import.meta.env.VITE_APP_SHORT_CODE}_session`
const KEY_IMPERSONATE = `${import.meta.env.VITE_APP_SHORT_CODE}_impersonate`

export const impersonateSession = (session: ISession) => {
  const current = getSession()
  localStorage.setItem(KEY_IMPERSONATE, btoa(JSON.stringify(current)))
  saveSession(session)
  return
}

export const saveSession = (session: ISession) => {
  localStorage.setItem(KEY, btoa(JSON.stringify(session)))
}

export const getSession = (): ISession | null => {
  const data = localStorage.getItem(KEY)
  return data ? JSON.parse(atob(data)) : null;
}

export const getImpersonateSession = (): ISession | null => {
  const data = localStorage.getItem(KEY_IMPERSONATE)
  return data ? JSON.parse(atob(data)) : null;
}

export const removeImpersonateSession = () => {
  localStorage.removeItem(KEY_IMPERSONATE)
}