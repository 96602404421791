import {configureStore} from "@reduxjs/toolkit";

import loading from "./slices/loadingSlice";
import session from "./slices/sessionSlice";
import permissions from "./slices/permissionsSlice";
import frontendUtilities from "./slices/futilsSlice";
import userMe from "./slices/userMeSlice";
import searcher from "./slices/searcher";
import companies from "./slices/companies";
import sessionSlice from "./slices/sessionSlice";
import notifications from "./slices/notificationsSlice";
import statementSlice from "./slices/statement";
import tour from "./slices/tour";

const store = configureStore({
    reducer: {
        loading,
        session,
        permissions,
        frontendUtilities,
        statement: statementSlice,
        userMe,
        searcher,
        companies,
        sessionSlice,
        notifications,
        tour,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
