import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IReducedCompany from "../../interfaces/reduced/IReducedCompany.ts";
import {SetSelectedCompany} from "../../utils/selectCompany";

export interface ICompanies {
  all: IReducedCompany[];
  selected: any | null;
}

const initialState: ICompanies = {
  all: [],
  selected: null,
};

const slice = createSlice({
  name: EStores.COMPANIES,
  initialState,
  reducers: {
    setAll(state, action: PayloadAction<IReducedCompany[]>) {
      state.all = action.payload;
      return state;
    },
    selectCompany(state, action: PayloadAction<IReducedCompany>) {
      state.selected = action.payload;

      SetSelectedCompany(action.payload.id);

      return state;
    }
  },
});

export const {
  setAll,
  selectCompany,
} = slice.actions;

export default slice.reducer;
