import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import {getSession} from "../../../utils/sessionStorage";
import IReducedCompany from "../../../interfaces/reduced/IReducedCompany.ts";
import IPager from "../../../interfaces/IPager";
import {selectCompany, setAll} from "../../../store/slices/companies";
import {logout} from "../../../store/slices/sessionSlice.ts";

export default async function (
  dispatch: Dispatch,
): Promise<IResponse<IPager<IReducedCompany>>> {
  const session = getSession()
  if (!session?.token) {
    dispatch(logout())
    window.location.reload()
    throw new Error('Unauthorized')
  }

  const request = new RACARequest('me', 'myCompanies');

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data) {
    dispatch(setAll(response.data));

    if (response.data.length === 0) return response;

    const first = response.data[0];
    dispatch(selectCompany(first));
  }

  return response;
}
