import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes.ts";
import googleLoginRequest from "../../../core/api/requests/auth/googleLoginRequest.ts";
import { useDispatch } from "react-redux";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes.ts";
import sweetalert2 from "sweetalert2";
import { saveSession } from "../../../core/utils/sessionStorage.ts";
import GetReturnURL from "../../../utils/getReturnURL.ts";
import {useIntl} from "react-intl";

export default function(){
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const intl = useIntl()

    const login = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");

        if (!code) navigate(ERoutes.AUTH)
        
        const requestParams = {
            code,
            returnURL: `${GetReturnURL()}/login`,
            refreshToken: true
        }

        const response = await googleLoginRequest(dispatch, requestParams)
        if (response.statusCode === EHttpStatusCodes.OK) {
            saveSession(response.data)
            return
        }

        if (response.data.code === 'ERROR.OAUTH.USER_NOT_FOUND') {
          sweetalert2.fire(
            `Error`,
            intl.formatMessage({ id: 'ERROR.OAUTH.USER_NOT_FOUND_SIGNUP' }),
            'error',
          ).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(ERoutes.AUTH_SIGNUP)
            }
          });
          return
        }

        sweetalert2.fire(
          `Error`,
          response.data.message,
          'error',
        ).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(ERoutes.AUTH)
          }
        });
    }

    useEffect( () => {
        login()
    }, [])

    return null
}