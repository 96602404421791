
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import loginRequest, {ILoginRequest} from "../../../core/api/requests/auth/loginRequest.ts";
import {useDispatch} from "react-redux";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes.ts";
import {getSession, saveSession} from "../../../core/utils/sessionStorage.ts";
import ERoutes from "../../../core/enums/ERoutes.ts";
import {setSession} from "../../../core/store/slices/sessionSlice.ts";
import {useIntl} from "react-intl";
import googleCredentialsRequest from '../../../core/api/requests/auth/googleCredentialsRequest.ts'
import GetReturnURL from "../../../utils/getReturnURL.ts";

const initialValues = {
  email: '',
  password: '',
  rememberMe:false
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [errorAlert, setErrorAlert] = useState<string|null>(null)
  const dispatch = useDispatch()
  const intl = useIntl()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'VALIDATION.EMAIL_INVALID' }))
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const data: ILoginRequest = {
        email: values.email,
        password: values.password,
        refreshToken: values.rememberMe
      }

      const response = await loginRequest(dispatch, data)

      if (response.statusCode === EHttpStatusCodes.OK) {
        saveSession(response.data)
        return
      } else {
        setErrorAlert(intl.formatMessage({ id: response.data.code}))
      }
    },
  })

  useEffect(() => {
    const data = getSession()
    if (data) {
      dispatch(setSession(data))
    }
  }, []);


  const loginWithGoogle = async () => {
    const url = GetReturnURL()

    const params = {
      return_url: `${url}/login`
    }
    const {data} = await googleCredentialsRequest(dispatch, params)
    if(data.url){
      window.open(data.url, '_self')
    }
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-gray-900 fw-bolder mb-3'>{intl.formatMessage({ id: 'TITLES.SIGN_IN' })}</h1>
        <div className='text-gray-500 fw-semibold fs-6'>{intl.formatMessage({ id: 'LOGIN.SOCIAL_ACCESS' })}
        </div>
      </div>
      {/* begin::Heading */}

      <div className='row g-3 mb-9 justify-content-center'>
        <div className='col-md-6'>
          <span onClick={() => loginWithGoogle()}
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            {intl.formatMessage({ id: 'LOGIN.SIGN_IN_GOOGLE' })}
          </span>
        </div>
      </div>

      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>{intl.formatMessage({ id: 'LOGIN.OR_WITH_EMAIL' })}</span>
      </div>
      {/* end::Separator */}
      {errorAlert &&
        <div className="mb-lg-15 alert text-danger bg-light-danger">
          <div className="alert-text font-weight-bold">{errorAlert}</div>
        </div>
      }

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.EMAIL' })}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <input
          placeholder={intl.formatMessage({ id: 'PLACEHOLDERS.PASSWORD' })}
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>

        <label className='form-check form-check-inline' htmlFor='kt_login_remember_me'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_remember_me'
            {...formik.getFieldProps('rememberMe')}
          />
          <span>
            {intl.formatMessage({ id: 'PLACEHOLDERS.REMEMBER_ME' })}
          </span>
        </label>

        {/* begin::Link */}
        <Link to={ERoutes.AUTH_PASSWORD_RECOVERY_REQUEST} className='link-primary'>
          {intl.formatMessage({ id: 'LOGIN.PASSWORD_RECOVERY' })}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>
            {intl.formatMessage({ id: 'COMMON.CONTINUE' })}
          </span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({ id: 'COMMON.PLEASE_WAIT' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        {intl.formatMessage({ id: 'LOGIN.NOT_A_MEMBER_YET' })}
        {' '}
        <Link to={ERoutes.AUTH_SIGNUP} className='link-primary'>
          {intl.formatMessage({ id: 'COMMON.SIGN_UP' })}
        </Link>
      </div>
    </form>
  )
}
